import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import { PromptTemplateEditor } from '../components/PromptTemplate/PromptTemplateEditor';
import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export const loader = async (action: ClientLoaderFunctionArgs) => {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [r1] = await Promise.all([
    tokenWithRedirect(
      () => apiService.promptTemplate.getTemplateById(id),
      action.request.url,
      { admin: true }
    ),
  ]);

  return {
    promptTemplate: r1.data.promptTemplate,
  };
};

export function Component() {
  const { promptTemplate } = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  useTitle(makeTitle(`${promptTemplate.name} - Prompt Template`));

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <PromptTemplateEditor
        template={promptTemplate}
        type={promptTemplate.type}
        initialEditing={false}
        onSave={() => {
          navigate(-1);
        }}
        onCancel={() => {
          navigate(-1);
        }}
      />
    </AdminView>
  );
}
